import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getExtension } from '../../helper';

const Image = memo(({ webpImage, src, className, ...otherProps }) => {
  const extension = getExtension(src);
  return (
    <picture>
      {Boolean(webpImage) && <source type="image/webp" srcSet={webpImage} />}
      <source type={`image/${extension}`} srcSet={src} />
      <img
        alt={otherProps.alt}
        {...otherProps}
        className={className}
        src={src}
      />
    </picture>
  );
});
Image.propTypes = {
  webpImage: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default Image;
