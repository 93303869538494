import React from 'react';
import styles from './Superbrain.module.css';
import Descriptor from '../../../Components/Descriptor';
import Image from '../../../Components/Image';
import { SUPER_BRAIN_URL } from '../../../constants';
import TESTIMAGEjpg from '../../../assets/superbrain/isb-brand.jpg';
import SUPERBRAINpng from '../../../assets/superbrain/superBrain.png';
import SUPERBRAINwebp from '../../../assets/superbrain/superBrain.webp';
import RECTANGLEpng from '../../../assets/brand_sociana/square.png';
import TESTIMAGEwebp from '../../../assets/superbrain/isb-brand.webp';
import RECTANGLEwebp from '../../../assets/brand_sociana/square.webp';

const description =
  "From Kashmir to Kanyakumari the citizens speak of different tales, but the basic need remains the same: truth, courage, honour and the desire to be more than expected. However, this wish stays inactive unless it finds an opportunity. Here on India's Superbrain, we bring you this opportunity. We judge you on your ability to grasp concepts, and not on what you already know. The time for action is upon us now. If you were ever confused about which career options to pursue or if you ever felt that you have an ability for completely grasping a given topic and excelling at it so much so that you can compete with others, well, then this is the competition you're looking for. This is India's most unique competition ever. But what if you think you're not yet ready for the competition? Don't worry. We've got you covered. You get formal mentoring before being asked to appear for the competition.It is a 4 week long competition which shall be judged in a leaderboard format. An unbiased competition full of ultimate opportunities.Opportunity to grow and establish yourself as a working professional in a formidable field. Opportunity to succeed, to learn and to earn. Opportunity that you are looking for.So, make the best use of the lockdown and dont let the pandemic affect your career. Set course for the ultimate royale, where all good things begin -  - India's Superbrain.";
const Superbrain = () => {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.childCotainer}>
        <div className={styles.squareBackGround}>
          <Image
            alt="Superbrain"
            src={RECTANGLEpng}
            webpImage={RECTANGLEwebp}
            className={styles.square}
          />
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.imageBox}>
            <Image
              webpImage={TESTIMAGEwebp}
              className={styles.image}
              src={TESTIMAGEjpg}
            />
          </div>
        </div>
        <div className={styles.logoTextContainer}>
          <Descriptor
            description={description}
            descriptorAlignment={styles.superbrainTextAlign}
            webpImage={SUPERBRAINwebp}
            image={SUPERBRAINpng}
            alt="INDIA'S SUPERBRAIN"
            knowMoreText="LEARN MORE"
            descriptionStyle={styles.descriptorStyle}
            maxDescriptionCharacters={375}
            URL={SUPER_BRAIN_URL}
          />
        </div>
      </div>
    </div>
  );
};
export default Superbrain;
