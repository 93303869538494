import React from 'react';
import PropTypes from 'prop-types';
import styles from './KnowMore.module.css';
import Icon from '../Icon';
import { openUrl } from '../../helper';

const KnowMore = ({
  iconName,
  text,
  haveBorder,
  isLight,
  URL,
  disabled = false
}) => {
  const openWebsite = () => {
    if (!URL) return;
    openUrl(URL);
  };
  return (
    <div
      className={styles.container}
      style={disabled ? { cursor: 'not-allowed' } : {}}
      onClick={openWebsite}>
      <span
        className={`${isLight ? styles.light_text : ''} readmoreText ${
          styles.textContainer
        }`}
        style={disabled ? { color: 'grey' } : {}}>
        {text}
      </span>
      <button
        type="button"
        className={`${styles.buttonContainer} ${
          haveBorder ? styles.remove_border : ''
        }`}
        disabled={disabled}
        style={disabled ? { cursor: 'not-allowed', borderColor: 'grey' } : {}}>
        <Icon name={iconName} disabled={disabled} size="lg" />
      </button>
    </div>
  );
};

export default KnowMore;

KnowMore.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  haveBorder: PropTypes.bool,
  isLight: PropTypes.bool,
  URL: PropTypes.string
};

KnowMore.defaultProps = {
  haveBorder: false,
  isLight: false,
  URL: ''
};
