import React from 'react';
import PropTypes from 'prop-types';
import KnowMore from '../Know More';
import Image from '../Image';
import styles from './Descriptor.module.css';
import ShortText from '../ShortText';

const Descriptor = ({
  descriptorAlignment,
  descriptionStyle,
  description,
  title,
  image,
  alt,
  knowMoreText,
  haveIconBorder,
  titleStyle,
  isLight,
  webpImage,
  titleInlineStyle,
  descriptionInlineStyle,
  maxDescriptionCharacters,
  URL,
  disabled = false
}) => {
  return (
    <div className={styles.container}>
      {Boolean(image || webpImage) && (
        <div
          data-testid="descriptor-image"
          className={`${styles.image_container} ${descriptorAlignment}`}>
          <div className={`${styles.image} `}>
            <Image
              alt={alt}
              className={styles.image}
              webpImage={webpImage}
              src={image}
            />
          </div>
        </div>
      )}
      {Boolean(title) && (
        <span
          data-testid="descriptor-title"
          className={`titleText  ${descriptorAlignment}  ${titleStyle}  `}
          style={titleInlineStyle}>
          {title}
        </span>
      )}
      <p
        className={`bodyText ${styles.description} ${descriptorAlignment} ${descriptionStyle}`}
        style={descriptionInlineStyle}>
        <ShortText
          text={description}
          maxCharacters={maxDescriptionCharacters}
        />
      </p>
      {Boolean(knowMoreText) && (
        <div
          data-testid="knowMoreText"
          className={`${descriptorAlignment} ${styles.KnowMoreContainer} `}>
          <KnowMore
            iconName="fas fa-angle-right"
            text={knowMoreText}
            haveBorder={haveIconBorder}
            isLight={isLight}
            URL={URL}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};
Descriptor.propTypes = {
  descriptorAlignment: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  descriptionStyle: PropTypes.string,
  knowMoreText: PropTypes.string,
  haveIconBorder: PropTypes.bool,
  titleStyle: PropTypes.string,
  URL: PropTypes.string,
  isLight: PropTypes.bool,
  webpImage: PropTypes.string,
  titleInlineStyle: PropTypes.shape({
    maxWidth: PropTypes.string
  }),
  descriptionInlineStyle: PropTypes.shape({
    maxWidth: PropTypes.string
  }),
  maxDescriptionCharacters: PropTypes.number
};
Descriptor.defaultProps = {
  title: '',
  image: '',
  alt: 'Description image',
  descriptionStyle: '',
  knowMoreText: '',
  haveIconBorder: false,
  titleStyle: '',
  URL: '',
  isLight: false,
  webpImage: '',
  titleInlineStyle: {
    maxWidth: 'unset'
  },
  descriptionInlineStyle: {
    maxWidth: 'unset'
  },
  maxDescriptionCharacters: 400
};
export default Descriptor;
