export const BLOG_URL = 'https://medium.com/alienbrains';
export const SUPER_BRAIN_URL = 'https://isb.alienbrains.in/';
export const SOCIANA_URL = 'https://sociana.com/';
export const EDUCATIONS_URL = 'https://educations.alienbrains.in/';

//  =================Policy Page=====================

export const POLICY_TEXT_0 = `Privacy Policy`;
export const POLICY_TEXT_2 =
  ' This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.';
export const POLICY_TEXT_3 =
  'If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.';
export const POLICY_TEXT_4 =
  ' The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Sociana unless otherwise defined in this Privacy Policy.';
export const POLICY_TEXT_5 = ' Information Collection and Use';
export const POLICY_TEXT_6 = `For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Email Address, Phone Number, Gender. The information that we request will be retained by us and used as described in this privacy policy.`;
export const POLICY_TEXT_7 =
  ' The app does use third party services that may collect information used to identify you.';
export const POLICY_TEXT_8 =
  ' Link to privacy policy of third party service providers used by the app :';
export const POLICY_TEXT_9 = 'Google Play Services';
export const POLICY_TEXT_10 = 'Log Data';
export const POLICY_TEXT_11 = `We want to inform you that whenever you use our Service, in a case of
an error in the app we collect data and information (through third
party products) on your phone called Log Data. This Log Data may
include information such as your device Internet Protocol ("IP")
address, device name, operating system version, the configuration of
the app when utilizing our Service, the time and date of your use of
the Service, and other statistics.`;
export const POLICY_TEXT_12 = `Cookies`;
export const POLICY_TEXT_13 = `Cookies are files with a small amount of data that are commonly used
as anonymous unique identifiers. These are sent to your browser from
the websites that you visit and are stored on your device's internal
memory.`;
export const POLICY_TEXT_14 = ` This Service does not use these "cookies" explicitly. However, the app
may use third party code and libraries that use "cookies" to collect
information and improve their services. You have the option to either
accept or refuse these cookies and know when a cookie is being sent to
your device. If you choose to refuse our cookies, you may not be able
to use some portions of this Service.`;
export const POLICY_TEXT_15 = `Service Providers`;
export const POLICY_TEXT_16 = `We may employ third-party companies and individuals due to the
following reasons:`;
export const POLICY_TEXT_17 = `To facilitate our Service;`;
export const POLICY_TEXT_18 = `To provide the Service on our behalf;`;
export const POLICY_TEXT_19 = `To perform Service-related services; or`;
export const POLICY_TEXT_20 = `To assist us in analyzing how our Service is used.`;
export const POLICY_TEXT_21 = ` We want to inform users of this Service that these third parties have
access to your Personal Information. The reason is to perform the
tasks assigned to them on our behalf. However, they are obligated not
to disclose or use the information for any other purpose.`;
export const POLICY_TEXT_22 = `Security`;
export const POLICY_TEXT_23 = ` We value your trust in providing us your Personal Information, thus we
are striving to use commercially acceptable means of protecting it.
But remember that no method of transmission over the internet, or
method of electronic storage is 100% secure and reliable, and we
cannot guarantee its absolute security.`;
export const POLICY_TEXT_24 = `Links to Other Sites`;
export const POLICY_TEXT_25 = ` This Service may contain links to other sites. If you click on a
third-party link, you will be directed to that site. Note that these
external sites are not operated by us. Therefore, we strongly advise
you to review the Privacy Policy of these websites. We have no control
over and assume no responsibility for the content, privacy policies,
or practices of any third-party sites or services.`;
export const POLICY_TEXT_26 = `Children's Privacy`;
export const POLICY_TEXT_27 = `These Services do not address anyone under the age of 13. We do not
knowingly collect personally identifiable information from children
under 13. In the case we discover that a child under 13 has provided
us with personal information, we immediately delete this from our
servers. If you are a parent or guardian and you are aware that your
child has provided us with personal information, please contact us so
that we will be able to do necessary actions.`;
export const POLICY_TEXT_28 = `Changes to This Privacy Policy`;
export const POLICY_TEXT_29 = `We may update our Privacy Policy from time to time. Thus, you are
advised to review this page periodically for any changes. We will
notify you of any changes by posting the new Privacy Policy on this
page. These changes are effective immediately after they are posted on
this page.`;
export const POLICY_TEXT_30 = `Contact Us`;
export const POLICY_TEXT_31 = `If you have any questions or suggestions about our Privacy Policy, do
not hesitate to contact us at : `;
export const POLICY_TEXT_32 = `admin@alienbrains.in`;
